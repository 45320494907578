<template>
  <section>
    <div class="card">
      <div class="card-body article-filter">
        <form>
          <div class="row m-t-10">
            <div class="col-lg-2 col-md-4">
              <app-datepicker
                type="date"
                v-model="filter.dateFrom"
                id="filter_dateFrom"
                :label="$t('videoStats.filter.dateFrom')"
                disable-form-group
                @change="submitFilter"
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-4">
              <app-datepicker
                type="date"
                v-model="filter.dateTo"
                id="filter_dateTo"
                :label="$t('videoStats.filter.dateTo')"
                disable-form-group
                @change="submitFilter"
              >
              </app-datepicker>
            </div>
            <div class="col-lg-8 col-md-4 text-right">
              <slot name="buttons"></slot>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Datepicker from '../../form/Datepicker'

export default {
  name: 'KpiReportsFilter',
  components: {
    AppDatepicker: Datepicker
  },
  data () {
    return {
      isLoading: false,
      filter: {
        dateFrom: '',
        dateTo: ''
      },
      ready: false
    }
  },
  mounted () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    this.filter.dateFrom = firstDay.toISOString()
    this.filter.dateTo = lastDay.toISOString()
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    submitFilter () {
      if (this.ready === true) {
        this.$emit('filter', this.filter)
      }
    }
  }
}
</script>

<style src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.article-filter {
  label {
    margin-bottom: 0;
  }
}

.article-filter-search-buttons {
  margin-top: 24px;
}
</style>
